<!-- Wrapper-->
<div id="wrapper">

    <!-- Main page wrapper -->
    <div id="page-wrapper" class="gray-bg">
      
      <!-- Top navigation -->
      <topnavbar-cmp *ngIf="hideNavbarFooter"></topnavbar-cmp>
      
      <!-- Main view/routes wrapper-->
      <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
      <router-outlet class="app-main-component"></router-outlet>
      <!-- Footer -->
      <footer-cmp *ngIf="hideNavbarFooter"></footer-cmp>
    </div>
    <!-- End page wrapper-->
  </div>
  <!-- End wrapper-->
  
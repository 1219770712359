import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authSerice: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          const interceptedURL = req.url;
          if (event instanceof HttpResponse && event.status === 200 && event.body.authorized === false) {
            console.log('[AUTH INTERCEPTOR] - ', event);
            if (event.body.UserInDB) {
              // AUTH FALSE AND USER IN DB TRUE
              this.clearAllStorage();
              window.location.href = `${window.location.origin}/notregistered`;
            } else {
              // AUTH FALSE AND USER IN DB FALSE
              this.clearAllStorage();
              window.location.href = `${window.location.origin}/unauthorize`;
            }
          }
        }, 
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // redirect to the login route
              // or show a modal
              this.clearAllStorage();
              window.location.href = `${window.location.origin}/unauthorize`;
            }
          }
        }
      })
    );
  }
  clearAllStorage() {
    localStorage.removeItem('ContextData');
    localStorage.removeItem('PageData');
    localStorage.removeItem('AssersionToken');
    localStorage.removeItem('UserInfo');
    localStorage.removeItem('UserCertInfo');
    localStorage.removeItem('relid');
    localStorage.removeItem('selimv');
    sessionStorage.removeItem('ContextData');
    sessionStorage.removeItem('PageData');
    sessionStorage.removeItem('AssersionToken');
    sessionStorage.removeItem('UserInfo');
    sessionStorage.removeItem('UserCertInfo');
    sessionStorage.removeItem('relid');
    sessionStorage.removeItem('selimv');
}
}

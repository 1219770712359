import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HideNavBarFooterGuard implements CanActivate {
  private hideNavBarFooterSubject = new BehaviorSubject<boolean>(true);

  get hideNavBarFooter() {
    return this.hideNavBarFooterSubject.asObservable();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const hideNavBarFooter = route.data['hideNavBarFooter'];
    this.hideNavBarFooterSubject.next(hideNavBarFooter);
    return true;
  }
}
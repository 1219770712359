import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
console.log(`IS PRODUCTION MODE?: ${environment.production} - [${environment.verWeb}] - ${window.location.href}`);
import { AuthInterceptor } from './services/interceptors/auth.interceptor';
import { FooterModule } from './footer/footer.module';
import { TopNavbarModule } from './topnavbar/topnavbar.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { app } from '@microsoft/teams-js';
export function initializeApp() {
  return () =>
    app.initialize().then((onfulfilled) => {
      app.notifySuccess();
      console.log('[APP MODULE] - MS TEAMS IS GOOD TO GO', onfulfilled);
    }).catch((onrejected) => {
      console.log('[APP MODULE] - NOT MS TEAMS ALL', onrejected);
      const failReq: app.IFailedRequest = {
        reason: app.FailedReason.AuthFailed,
        message: 'Not within Teams Application'
      }
      app.notifyFailure(failReq);
    });
}
import { init,createErrorHandler, BrowserTracing, Replay, routingInstrumentation } from '@sentry/angular-ivy';
init({
  dsn: "https://4d5b651dd131477699222cf4f6f1ee44@o262274.ingest.sentry.io/4505230874050560",
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new BrowserTracing({
      tracePropagationTargets: ["localhost", environment.urlpath],
      routingInstrumentation: routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FooterModule,
    TopNavbarModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { app, authentication, pages } from '@microsoft/teams-js';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ErrorService } from '../errorservice/error.service';
import { HeaderService } from '../header/header.service';
declare let gtag: Function;
interface UserInfo {Photo: string, Profile: {"@odata.context":string,businessPhones:any[],displayName: string, givenName: string, id: string, jobTitle: any,mail: string,mobilePhone:any,officeLocation:any,preferredLanguage:string,surname: string,userPrincipalName:string}}
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private headerService: HeaderService, private errorService: ErrorService, private router: Router) { }
    GetTeamConfigMaps(){
        return this.http.get(`${this.url}/mdash/team_config`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    SaveTeamConfigMaps(actv_code:string,map:{id: number, name: string}, tabName: string){
        let body = new FormData();
        if(actv_code){
            body.append('actv_code', actv_code);
        }else{
            body.append('licenseId', map.id.toString());
        }
        body.append('tabName', tabName);
        return this.http.post(`${this.url}/mdash/team_config`, body,{ headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    DeleteTeamConfigMaps() {
        const httpOptions = {
            headers: this.headerService.returnHeader(),
            body: null
        };
        return this.http.delete(`${this.url}/mdash/team_config`, httpOptions).pipe(catchError(this.errorService.handleHttpError));
    }
    getAssertionToken(): string | null {
        const token = sessionStorage.getItem('AssersionToken');
        return token?token:null;
    }
    getPageContextData(): pages.InstanceConfig | null {
        const pageData = sessionStorage.getItem('PageData');
        return pageData?JSON.parse(pageData):null;
    }
    getMainContextData(): app.Context | null {
        const contextData = sessionStorage.getItem('ContextData');
        return contextData?JSON.parse(contextData):null;
    }
    clearAllStorage(clearLocal?:boolean) {
        // sessionStorage.clear();
        if(clearLocal){
          localStorage.removeItem('ContextData');
          localStorage.removeItem('PageData');
          localStorage.removeItem('AssersionToken');
          localStorage.removeItem('AssersionTokenData');
          localStorage.removeItem('UserInfo');
          localStorage.removeItem('UserCertInfo');
          localStorage.removeItem('relid');
          localStorage.removeItem('selimv');
        }else{
          sessionStorage.removeItem('ContextData');
          sessionStorage.removeItem('PageData');
          sessionStorage.removeItem('AssersionToken');
          sessionStorage.removeItem('AssersionTokenData');
          sessionStorage.removeItem('UserInfo');
          sessionStorage.removeItem('UserCertInfo');
          sessionStorage.removeItem('relid');
          sessionStorage.removeItem('selimv');
        }
    }
    getReRouteURL(): string | null {
      const reRouteURL = sessionStorage.getItem('reroute');
      return reRouteURL?reRouteURL:null;
    }
    clearSessionRerouteKey(){
      sessionStorage.removeItem('reroute');
    }
    GetUserInfoFromLStorage():UserInfo{
        const userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
		if(userInfo && userInfo.hasOwnProperty('Profile')){
            return userInfo;
        } else {
            return null;
        }
    }
    GetUserCertInfoFromLStorage(){
      const userCertInfo = JSON.parse(sessionStorage.getItem('UserCertInfo'));
		  if(userCertInfo && userCertInfo.hasOwnProperty('qUser')){
            return userCertInfo.qUser;
        } else {
            return null;
        }
    }
    isITAccess(): boolean {
      const UserCertInfo = JSON.parse(sessionStorage.getItem('UserCertInfo'));
      return UserCertInfo && UserCertInfo.qUser[0].company_id === 7 && UserCertInfo.roles.includes('IT')?true:false;
    }
    isSalesFullAccess(): boolean {
      const UserCertInfo = JSON.parse(sessionStorage.getItem('UserCertInfo'));
      return UserCertInfo && UserCertInfo.roles.includes('Salesman~FullAccess')?true:false;
    }
    GetUserGraphInfo(){
        return this.http.get(`${this.url}/mdash/getUserGraphInfo`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    // Load MSTeams App
    async LoadMSTeamsApp(): Promise<void> {
      console.log('[AUTH SERVICE] - LOADING ALL TEAMS DATA');
      try {
          // Get context
          const context = await app.getContext();
          // get page data
          const pageData = await pages.getConfig();
          // Get authentication token
          const token = await authentication.getAuthToken();

          // Save context data to session storage
          sessionStorage.setItem('ContextData', JSON.stringify(context));
          // Save page data to session storage
          sessionStorage.setItem('PageData', JSON.stringify(pageData));
          // Save token to session storage
          sessionStorage.setItem('AssersionToken', token);

          console.log('[AUTH-SERVICE] - Getting Graph Info...');
          // Get user graph info
          try {
            const resp: any = await this.http.get(`${this.url}/mdash/getUserGraphInfo`, { headers: this.headerService.returnHeader() }).toPromise();
            if(!resp.hasOwnProperty('UserGraphInfo')){
              if (resp.UserInDB) {
                let UserCertInfo = resp.cert;
                let first_name = '', last_name = '', email: '', ipAddress = '';
                  if(UserCertInfo && UserCertInfo.qUser && UserCertInfo.qUser.length){
                    email = UserCertInfo.qUser[0].email;
                    first_name = UserCertInfo.qUser[0].first_name;
                    last_name = UserCertInfo.qUser[0].last_name;
                    ipAddress = resp.ipd?resp.ipd:'';
                  }
                  gtag('event', 'ted', {
                    el: `${first_name} ${last_name}`,
                    cd6: ipAddress,
                    cd1: email,
                    ec: 'TED | No Subscription',
                    page_location: '/notregistered',
                    send_to: 'G-834D8WQXDD'
                  });
                this.clearAllStorage();
                // AUTH FALSE AND USER IN DB TRUE
                this.router.navigate(['/notregistered']);
                return;
              } else {
                let email = context?context.user.loginHint:'';
                gtag('event', 'ted', {
                  el: `Within Teams`,
                  cd6: resp.ipd?resp.ipd:'',
                  cd1: email,
                  ec: 'TED | Not Registered',
                  page_location: '/unauthorize',
                  send_to: 'G-834D8WQXDD'
                });
                this.clearAllStorage();
                // AUTH FALSE AND USER IN DB FALSE
                this.router.navigate(['/unauthorize']);
                return;
              }
            }
            if (Object.keys(resp.UserGraphInfo.err).length === 0) {
              console.log('USER GRAPH INFO ', resp.UserGraphInfo);

              // Save user info to session storage
               sessionStorage.setItem('UserInfo', JSON.stringify(resp.UserGraphInfo.data));

              if (resp.cert) {
                sessionStorage.setItem('UserCertInfo', JSON.stringify(resp.cert));
              }

              return; // Everything is done, no need to call resolve() as we are returning directly
            } else {
              // Show error message
              const errArray = [];
              Object.keys(resp.UserGraphInfo.err).map(e => errArray.push(`${resp.UserGraphInfo.err[e]}`));
              Swal.fire({
                title: 'Error Occured!',
                text: errArray.join(),
                icon: 'error',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok, I got it!',
              });

              throw new Error('Error occurred while fetching user graph info');
            }
          } catch (error) {
            console.error('Error occurred while fetching user graph info:', error);
            throw error;
          }
      } catch (error) {
        console.error('Error in LoadMSTeamsApp:', error);
        throw error;
      }
    }

}

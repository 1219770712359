import { Injectable } from '@angular/core';
import { HttpErrorResponse} from '@angular/common/http';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
    constructor() { }

  handleHttpError(error: HttpErrorResponse) {
    // Do something with the error
    console.error(error);
    return throwError(() => new Error(error.message || 'Server Error on Server Occured'));
  }

  handleClientError(error: Error) {
    // Do something with the error
    console.error(error);
    return throwError(() => new Error(error.message || 'Client Side ERROR Occured'));
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TopnavbarRoutingModule } from './topnavbar-routing.module';
import { AvatarModule } from 'ngx-avatars';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule } from '@angular/forms';
import { TopNavbarComponent } from './topnavbar.component';
@NgModule({
  declarations: [
    TopNavbarComponent
  ],
  imports: [
    CommonModule,
    TopnavbarRoutingModule,
    AvatarModule,
    AutoCompleteModule,
    FormsModule
  ],
  exports: [TopNavbarComponent]
})
export class TopNavbarModule { }

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { app, pages } from '@microsoft/teams-js';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(private authService: AuthenticationService,private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise<boolean>(async(resolve, reject) => {
        const contextData = this.authService.getMainContextData();
        const currentContextPage = (await app.getContext()).page;
        const currentPageContextPage: pages.InstanceConfig = (await pages.getConfig());
        const currentChanelData = (await app.getContext()).channel;
        const assersionToken = this.authService.getAssertionToken(); 
        const userCertInfo = this.authService.GetUserCertInfoFromLStorage();
        console.log('[LOGIN GUARD] - URL and CONTEXT', state.url, contextData, currentContextPage, currentChanelData,currentPageContextPage);
        // NO TOKEN - NO MS TEAMS
        if(!app.isInitialized() && !assersionToken && state.url.includes('/config')){
            this.authService.clearAllStorage();
            this.router.navigate(['/unauthorize']);
            return;
        }
        // NAVIGATE TO HOME
        if(state.url.includes('/config') && assersionToken && userCertInfo){
          if(currentContextPage && currentContextPage.subPageId){
            resolve(false);
            this.router.navigate([`/${currentContextPage.subPageId}`]);
            return;
          }else{
            resolve(false);
            this.router.navigate([`/home`]);
            return;
          }
        }
        // LOGIN TOKEN CHECK
        if(!assersionToken){
          console.log(`[LOGIN-GUARD] - NO TOKEN: ${state.url}`);
          this.authService.clearAllStorage();
          this.authService.LoadMSTeamsApp().then(()=>{
          if(currentContextPage && currentContextPage.subPageId){
            resolve(false);
            this.router.navigate([`/${currentContextPage.subPageId}`]);
            return;
           }else if( state.url.includes('/tabconfig')){
              resolve(false);
              this.router.navigate([`/tabconfig`]);
              return;
            }else if (state.url.includes('/searchconfig') || state.url.includes('/article') || state.url.includes('/viewsector') ||
             state.url.includes('/searchreadsaved') || state.url.includes('/indicator') || state.url.includes('/tabremove')) {
              resolve(false);
              this.router.navigateByUrl(state.url);
              return;
            }else{
              resolve(false);
              this.router.navigate([`/home`]);
              return;
            }
          }).catch((error) => {
            console.error('[LOGIN-GUARD] - NO TOKEN ERROR:', error);
            resolve(false);
            setTimeout(() => {
              this.router.navigate(['/unauthorized']);
            }, 1000);
          });
        }else if(assersionToken && !userCertInfo){
          this.authService.clearAllStorage();
          resolve(false);
          setTimeout(() => {
            this.router.navigate(['/notregistered']);
          }, 10);
        }else {
          resolve(true);
        }
      });
  }
}
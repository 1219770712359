import { Component, OnInit, OnDestroy } from '@angular/core';
import { HideNavBarFooterGuard } from './services/guards/navfooter.guard';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
import { app } from '@microsoft/teams-js';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hideNavbarFooter = false;
  constructor(private hideNavBarFooterGuard: HideNavBarFooterGuard, private router: Router, private authService: AuthenticationService) {
    this.hideNavBarFooterGuard.hideNavBarFooter.subscribe(hideNavbarFooter => {
      this.hideNavbarFooter = hideNavbarFooter;
      const classToAddRemove = "boxed-layout fixed-nav";
      if (this.hideNavbarFooter) {
        $('body').addClass(classToAddRemove);
      } else {
        $('body').removeClass(classToAddRemove);
      }
    });
    if(app.isInitialized() === false){
      this.router.navigate(['/unauthorize']);
    }else{
      this.authService.clearAllStorage();
    }
  }

  ngOnInit(){}
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

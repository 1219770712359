export const environment = {
  mode: 'prod',
  urlpath: 'https://api.industryintel.com/api',
  verWeb: require('../../package.json').version,
  locale: 'en',
  production: true,
  msAppId: '8c3548cd-e541-43d9-ab53-5251cc67ac61',
  i2EntityId: 'b5a8b04b-f8fa-4961-9fc9-bc6f0d4b6bff',
  i2TenantId: 'dda4d46e-0f10-443e-92bd-7b500b4f9e7f',
  gtag: '16Pnv-kpR2mjno_eO5kA-Q'
};

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
@Component({
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy, AfterViewInit {
    private subscription: Subscription = new Subscription();
    public todaysDate: number = Date.now();
    constructor() { }
    get verWeb(): number{
        return environment.verWeb?environment.verWeb:null;
    } 
    ngOnInit() {
    }
    ngAfterViewInit() {

    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

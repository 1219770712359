
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { MDashService } from '../services/msdash/mdash.service';
import { app } from '@microsoft/teams-js';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { ErrorService } from '../services/errorservice/error.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnalyticService } from '../services/analytic/analytic.service';
interface UserInfo {Photo: string, Profile: {"@odata.context":string,businessPhones:any[],displayName: string, givenName: string, id: string, jobTitle: any,mail: string,mobilePhone:any,officeLocation:any,preferredLanguage:string,surname: string,userPrincipalName:string}}
@Component({
    selector: 'topnavbar-cmp',
    templateUrl: 'topnavbar.component.html',
    styleUrls: ['topnavbar.component.css']
})
export class TopNavbarComponent implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe$ = new Subject<void>();
    public i2Image = 'assets/images/i2logofull.png';
    public isTeams = app.isInitialized();
    public isNonTeamLoggedIn: boolean = false;
    public SearchSuggestData:{type: string, value: string}[];
    public searchText: string;
    public isMobile = this.deviceService.isMobile();
    public isTablet = this.deviceService.isTablet();
    constructor(public authenticationService: AuthenticationService, private router: Router, private authService: AuthenticationService, private mdashService: MDashService,
    private errorService: ErrorService, private deviceService: DeviceDetectorService, private analytic: AnalyticService) {
    }
    ngOnInit() {    }
    get userInfo():UserInfo{
       return this.authService.GetUserInfoFromLStorage();
    }
    routeHome() {
       this.router.navigate(['/home']);
    }
    ngAfterViewInit() {
        $('.loader').remove();
        var mainHeader = $('#navbarscroll')
        //set scrolling variables
        var scrolling = false,
            previousTop = 0,
            currentTop = 0,
            scrollDelta = 10,
            scrollOffset = 150;
        $(window).on('scroll', function () {
            if (!scrolling) {
                scrolling = true;
                (!window.requestAnimationFrame)
                    ? setTimeout(autoHideHeader, 250)
                    : requestAnimationFrame(autoHideHeader);
            }
        });
        function autoHideHeader() {
            var currentTop = $(window).scrollTop();
            checkSimpleNavigation(currentTop)
            previousTop = currentTop;
            scrolling = false;
        }
        function checkSimpleNavigation(currentTop) {
            //there's no secondary nav or secondary nav is below primary nav
            if (previousTop - currentTop > scrollDelta) {
                //if scrolling up...
                mainHeader.removeClass('is-hidden');
            } else if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
                //if scrolling down...
                mainHeader.addClass('is-hidden');
            }
        }
    }
    searchSuggest(query) {
        query = query.replace(/\s{2,}/g, ' ');
        this.mdashService.GetSearchSuggest(query).pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: (resp: any) => {
                if (Object.keys(resp.searchsuggest.err).length === 0) {
                     this.SearchSuggestData = resp.searchsuggest.data;
                } else {
                    const errArray = [];
                    Object.keys(resp.searchsuggest.err).map(e => errArray.push(`${resp.searchsuggest.err[e]}`));
                    Swal.fire({
                        title: 'Error Occured!',
                        text: errArray.join(),
                        icon: 'error',
                        confirmButtonText:'<i class="fa fa-thumbs-up"></i> Ok, I got it!',
                    });
                }
            },
            error: error => {
               this.errorService.handleClientError(error);
            }
        });
    }
    selectedSearch(value) {
        this.SearchSuggestData = [];
        setTimeout(() => {
            this.router.navigate(['/searchconfig'], { queryParams: { searchpattern: value.value } });
        }, 50);
    }
    selectedSearchEntered(stext) {
        if (stext) {
            if (typeof stext != 'string') { return; }
            stext = stext.replace(/\s{2,}/g, ' ');
            this.analytic.constructGoogleAnalytic('SP-Typed ' + stext, 'Search', 'Search View');
            if (stext.length >= 2) {
                setTimeout(() => {
                    this.router.navigate(['/searchconfig'], { queryParams: { searchpattern: stext } });
                }, 50);
            };
        };
    }
    RouteHome(){
        this.router.navigate(['/home']);
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}

import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { app } from '@microsoft/teams-js';
@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    private activeContextData: app.Context | null = null;
    constructor() {
        this.fetchActiveContextData();
     }

    returnHeader(): HttpHeaders {
        const isInsideTeams = app.isInitialized() ? 1 : 0;
        let _headers = new HttpHeaders();
        if (isInsideTeams) {
            const contextData = this.activeContextData;
            _headers = _headers.append('TeamsEntityId', `${contextData.page.id}`);
            _headers = _headers.append('TeamsTenantId', `${contextData.user.tenant.id}`);
            if(contextData.channel){
                _headers = _headers.append('TeamsChannel', JSON.stringify({tabId: contextData.page.id,channelId: contextData.channel.id, channelName: contextData.channel.displayName,teamGroupId: contextData.team.groupId, teamName: contextData.team.displayName}));
            }else if(contextData.chat){
                _headers = _headers.append('TeamsChat', `${contextData.chat.id}`);
            }
        }
        _headers = _headers.append('Authorization', `Bearer ${this.getAssertionTokenForHeader()}`);
        _headers = _headers.append('InsideTeams', isInsideTeams.toString());
        return _headers;
    }

    getAssertionTokenForHeader(): string | null {
        const token = sessionStorage.getItem('AssersionToken');
        return token?token:null;
    }

    async fetchActiveContextData(): Promise<void> {
        this.activeContextData = await app.getContext();
    }

}
import { catchError, firstValueFrom, forkJoin, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HeaderService } from '../header/header.service';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../errorservice/error.service';
import { app, pages, sharing } from '@microsoft/teams-js';
@Injectable({
    providedIn: 'root',
})
export class MDashService {
    private url = environment.urlpath;
    private apiVersion = "version=3.0.10"
    constructor(private http: HttpClient, private headerService: HeaderService, private errorService: ErrorService) { }

    GetMDashTree(){
        return this.http.get(`${this.url}/mdash`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    MdashLogOut(){
        return this.http.get(`${this.url}/mdash/logout`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetArticleById(id: string, extraParam?) {
        let Params = new HttpParams();
        Params = Params.append('id', id);
        Params = Params.append('fromtype', '1');
        Params = Params.append('usageRef', '99193');
        if(typeof extraParam === 'string'){
            Params = Params.append('uR', extraParam);
        } else if(typeof extraParam === 'object') {
            if(extraParam.hasOwnProperty('uR')){
                Params = Params.append('uR', extraParam.uR);
            }
            if(extraParam.hasOwnProperty('rName')){
                Params = Params.append('rName', extraParam.rName);
            }
        }
        Params = Params.append('rdm', Date.now().toString());
        return this.http.get(`${this.url}/mdash/story`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetMyIntelReportV2(parentId: number, relId: number, imvSelArry: any[], defItem: any) {
        let body = new FormData();
        body.append('parentId', parentId.toString())
        body.append('relationshipId', relId.toString())
        if (imvSelArry) {
            body.append('imvselect', imvSelArry.toString())
        }
        body.append('v', '3')
        body.append('def', JSON.stringify(defItem));
        return this.http.post(`${this.url}/mdash/getMyIntelReport`, body,
            { headers: this.headerService.returnHeader() }).pipe(map((item: any) => {
                item.getMyIntelReport.defItem = defItem;
                return item;
            }), catchError(this.errorService.handleHttpError));
    }
    GetCIOReport(parentId: number, relId: number, frX: { paramKey: string, paramValue: number },
        frXsz: { paramKey: string, paramValue: number }, frXgl: { paramKey: string, paramValue: number }, versionNumber?: number, imvSelectId?: number) {
        let Params = new HttpParams();
        Params = Params.append('parentId', parentId.toString())
        Params = Params.append('relationshipId', relId.toString());
        Params = Params.append('dashboard', '1');
        Params = Params.append(frX.paramKey, frX.paramValue.toString());
        Params = Params.append(frXsz.paramKey, frXsz.paramValue.toString());
        if (frXgl.paramValue) {
            Params = Params.append(frXgl.paramKey, frXgl.paramValue.toString());
        }
        if (versionNumber) {
            Params = Params.append('v', versionNumber.toString());
        }
        if (imvSelectId) {
            Params = Params.append('imvselect', imvSelectId.toString());
        }
        return this.http.get(`${this.url}/mdash/getMyIntelReport`, { params: Params, headers: this.headerService.returnHeader() });
    }
    GetLazyCIOReport(parentId: number, relId: number, frX: { paramKey: string, paramValue: number },
        frXsz: { paramKey: string, paramValue: number }, frXgl: { paramKey: string, paramValue: number }, versionNumber?: number, imvSelectId?: number) {
        let Params = new HttpParams();
        Params = Params.append('lazyload', 'true');
        Params = Params.append('parentId', parentId.toString());
        Params = Params.append('relationshipId', relId.toString());
        Params = Params.append('dashboard', '1');
        Params = Params.append(frX.paramKey, frX.paramValue.toString());
        Params = Params.append(frXsz.paramKey, frXsz.paramValue.toString());
        if (frXgl.paramValue) {
            Params = Params.append(frXgl.paramKey, frXgl.paramValue.toString());
        }
        Params = Params.append('v', versionNumber.toString());
        if (imvSelectId) {
            Params = Params.append('imvselect', imvSelectId.toString());
        }
        return this.http.get(`${this.url}/mdash/getMyIntelReport`, { params: Params, headers: this.headerService.returnHeader() });
    }
    GetStory(storyId: number) {
        let body = new FormData();
        body.append('id', storyId.toString());
        return this.http.post(`${this.url}/mdash/getStoryClob`, body,{ headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetSearchSuggest(queryText: string){
        let body = new FormData();
        body.append('q', queryText)
        return this.http.post(`${this.url}/mdash/searchsuggest?${this.apiVersion}`, body, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError))
    }
    GetIndicatorBySeriesId(seriesId: number) {
        return this.http.get(`${this.url}/mdash/getbenchmarkgraph?series=${seriesId}`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetTrendingByStoryData(articleId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        return this.http.get(`${this.url}/mdash/trending`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetStoryRelatedReports(storyId: string){
        let Params = new HttpParams();
        Params = Params.append('id', storyId);
        return this.http.get(`${this.url}/mdash/getRelatedReport`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetBenchMarkById(benchMarkId) {
        return this.http.get(`${this.url}/mdash/getBenchmark?id=${benchMarkId}`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    DeleteBenchMark(benchMarkId) {
        return this.http.get(`${this.url}/mdash/getBenchmark?id=${benchMarkId}`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetBenchMarkExcelById(benchMarkId, range) {
        let startDate = range.startDate;
        let endDate = range.endDate;
        return this.http.get(`${this.url}/mdash/getBenchmark?id=${benchMarkId}&startDate=${startDate}&endDate=${endDate}`, { headers: this.headerService.returnHeader(), responseType: 'blob', observe: 'response' }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetMyShortcutsById(topicId) {
        let Params = new HttpParams();
        Params = Params.append('rdm', Date.now().toString());
        return this.http.get(`${this.url}/mdash/topic2?${topicId}&${this.apiVersion}`, {params: Params,headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError))
    }
    GetChatGPTQuery(configParams:string, articlesArray: any[],subquery?:string) {
        let body = new FormData();
        body.append('configSource', configParams);
        body.append('article', JSON.stringify(articlesArray));
        body.append('rdm', Date.now().toString());
        if(subquery){
            body.append('subquery', subquery);
        }
        return this.http.post(`${this.url}/mdash/chatgpt_req`, body, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError))
    }
    GetMyChatGPT() {
        let Params = new HttpParams();
        Params = Params.append('rdm', Date.now().toString());
        return this.http.get(`${this.url}/mdash/my_chatgpt`, {params: Params,headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError))
    }
    GetRelatedStoryByMap(articleId: number, divisonId: number){
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        if(divisonId){
            Params = Params.append('divisionId', divisonId.toString());
        }
        return this.http.get(`${this.url}/mdash/getRelatedStories`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetActivationCode(config){
        let Params = new HttpParams();
        return this.http.get(`${this.url}/mdash/searchconfig2_activation?${config}&maxcountdefault=-1&${this.apiVersion}`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    async GetCompBySearchConfig2(config) {
        let Params = new HttpParams();
        Params = Params.append('rdm', Date.now().toString());
        let ReportData: any = await firstValueFrom(this.http.get(`${this.url}/mdash/searchconfig2?${config}&maxcountdefault=-1&${this.apiVersion}`,{ params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError)));
        if(ReportData){
            console.log('Check REPORT DATA',ReportData );
            let body = new FormData();
            body.append('aln', ReportData.searchconfig.searchTitle);
            let checkTopic: any = await firstValueFrom(this.http.post(`${this.url}/mdash/checktopic?${ReportData.searchconfig.qrystring}`, body, {  headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError)));
                console.log('Check TOPIC',checkTopic );
            if(checkTopic.hasOwnProperty('checktopic')){
                ReportData.searchconfig.topic = { status: checkTopic.checktopic.status, topicId: checkTopic.checktopic.topicId,
                topicName: checkTopic.checktopic.topicName, topicXML: checkTopic.checktopic.topicXML, teamAlertId: ReportData.searchconfig.topic.teamAlertId
                };
            }
        }
        return ReportData;
    }
    GetSearchConfigI2AI(sconfig: string){
        let Params = new HttpParams();
        Params = Params.append('searchpattern', sconfig);
        Params = Params.append('rdm', Date.now().toString());
        return this.http.get(`${this.url}/mdash/searchconfigi2AI`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetSearchConfigOpenAI(sconfig: string){
        let Params = new HttpParams();
        Params = Params.append('searchpattern', sconfig);
        Params = Params.append('rdm', Date.now().toString());
        return this.http.get(`${this.url}/mdash/searchconfigOpenAI`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetTeamCollab(articleId: string) {
        let Params = new HttpParams();
        Params = Params.append('story_id', articleId);
        return this.http.get(`${this.url}/mdash/team_collab`, {params: Params,headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError))
    }
    AddEditDeleteTeamCollab(action: 'post'|'put'|'delete', submitData: {story_id?:number,comment?:string,comment_id?:string}){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerService.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/mdash/team_collab`, httpOptions).pipe(catchError(this.errorService.handleHttpError));
        } else {
            return this.http[action](`${this.url}/mdash/team_collab`, JSON.stringify(submitData), { headers: this.headerService.returnHeader() }).pipe(
            catchError(this.errorService.handleHttpError));
        }
    }
    LikeTeamCollab(commentId: string){
        const body = {comment_id: commentId};
        return this.http.post(`${this.url}/mdash/team_collab_like`, JSON.stringify(body), { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError))
    }
    ShareToTeamsBot(botData:{articleId:number,article_type:string,groups:string,message:string,teams_channel: {channelId: string, teamId: string, channelName: string}[],users:string}){
        let body = new FormData();
        body.append('articleId', botData.articleId.toString());
        body.append('article_type', botData.article_type);
        body.append('message', botData.message);
        if(botData.teams_channel && botData.teams_channel.length){
            body.append('teams_channel', JSON.stringify(botData.teams_channel));
        }
        if(botData.users){
            body.append('users', botData.users);
        }
        if(botData.groups){
            body.append('groups', botData.groups);
        }
        return this.http.post(`${this.url}/mdash/teams_bot`, body, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError))
    }
    GetAllUserChatChannels(){
        return this.http.get(`${this.url}/mdash/user_chat_channels`, {headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError));
    }
    GetAllUserTeamsChannels(){
        return this.http.get(`${this.url}/mdash/user_team_channels`, {headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError));
    }
    async ShareToTeams(articleData:{id: number, title: string, fallbackurl?:string}): Promise<boolean>{
        const currentChannelData:app.ChannelInfo = (await app.getContext()).channel;
        const pageData: pages.InstanceConfig = await pages.getConfig();
        const msgText = `<br/><b>${articleData.title}</b>`;
        let mainContext:{subEntityId: string, channelId?:string} = {subEntityId: `article/${articleData.id}` };
        let entityId:string = environment.i2EntityId;
        if(currentChannelData && currentChannelData.id){
            mainContext = {...mainContext, channelId: currentChannelData.id};
            entityId = pageData.entityId;
        }
        const encodedContext = encodeURIComponent(JSON.stringify(mainContext));
        let URL = `https://teams.microsoft.com/l/entity/${environment.msAppId}/${entityId}?context=${encodedContext}`;
        // if(articleData.fallbackurl){
        //     URL = `${URL}&webUrl=${articleData.fallbackurl}`
        // }
        const shareWebContentRequest: sharing.IShareRequest<sharing.IURLContent> = {
          content:[
              {
                  type: 'URL',
                  url: URL, // Use the deep link here
                  message: msgText,
                  preview: false
              }
          ]
        }
        try {
            await sharing.shareWebContent(shareWebContentRequest);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
    GetMyReports(){
        return this.http.get(`${this.url}/mdash/report`, {headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError))
    }

    GetReportByCIO(reportId: number){
        let Params = new HttpParams();
        Params = Params.append('id', reportId.toString());
        return this.http.get(`${this.url}/mdash/reportCIO`, {params: Params,headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError))
    }
    GetReportById(reportId: number){
        let Params = new HttpParams();
        Params = Params.append('id', reportId.toString());
        return this.http.get(`${this.url}/mdash/report`, {params: Params,headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError))
    }
    GetSummarizeArticle(articleId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        return this.http.get(`${this.url}/mdash/summarize`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetGoogleLanguageTranslateList() {
        let Params = new HttpParams();
        Params = Params.append('v', '2');
        return this.http.get(`${this.url}/mdash/language`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetStoryGoogleTranslate(articleId: number, translateTo: string) {
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        Params = Params.append('translate_to', translateTo);
        return this.http.get(`${this.url}/mdash/translate2`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    RequestSendToClientCare(){
        return this.http.get(`${this.url}/mdash/report_req`, {headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError))
    }
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HideNavBarFooterGuard } from './services/guards/navfooter.guard';
import { LoginGuard } from './services/guards/login.guard';
import { RouteRoutingGuard } from './services/guards/reroute.guard';
const routes: Routes = [
  { path: '', redirectTo: 'config', pathMatch: 'full' },
  { path: 'config', loadChildren: () => import('./config/config.module').then(m => m.ConfigModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }},
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [RouteRoutingGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true } },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), canActivate: [HideNavBarFooterGuard], data: { 'hideNavBarFooter': true } },
  { path: 'tabconfig', loadChildren: () => import('./tabconfig/tabconfig.module').then(m => m.TabConfigModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }},
  { path: 'tabremove', loadChildren: () => import('./tabremove/tabremove.module').then(m => m.TabRemoveModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }},
  { path: 'article/:id', loadChildren: () => import('./article/article.module').then(m => m.ArticleModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'indicator', loadChildren: () => import('./indicator/indicator.module').then(m => m.IndicatorModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'searchconfig', loadChildren: () => import('./searchconfig/searchconfig.module').then(m => m.SearchconfigModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'viewsector', loadChildren: () => import('./searchconfig/searchconfig.module').then(m => m.SearchconfigModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'searchreadsaved', loadChildren: () => import('./searchconfig/searchconfig.module').then(m => m.SearchconfigModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'unauthorize', loadChildren: () => import('./unauthorize/unauthorize.module').then(m => m.UnauthorizeModule), canActivate: [HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }},
  { path: 'notregistered', loadChildren: () => import('./notregistered/notregistered.module').then(m => m.NotRegisteredModule), canActivate: [HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }},
    // Handle all other routes
  { path: '**', loadChildren: () => import('./error404/error404.module').then(m => m.error404Module), canActivate: [HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [HideNavBarFooterGuard]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { app, pages } from '@microsoft/teams-js';

@Injectable({
  providedIn: 'root'
})
export class RouteRoutingGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean | UrlTree>(async (resolve, reject) => {
      try {
        const config = await pages.getConfig();
        const contextData = await app.getContext();
        if (config.contentUrl && config.removeUrl && (config.contentUrl.includes('/searchconfig') || config.contentUrl.includes('/viewsector') || config.contentUrl.includes('/searchreadsaved'))) {
          const urlParse = new URL(config.contentUrl);
          console.log('PARSE URL: ', `${urlParse.pathname}${urlParse.search}`);
          this.router.navigateByUrl(`${urlParse.pathname}${urlParse.search}`);
          resolve(false);
        } else if(config.contentUrl && !config.removeUrl && state.url.includes('home') && !contextData.channel){
          this.router.navigateByUrl(`/reports`);
          resolve(false);
        } else {
          resolve(true);
        }
      } catch (error) {
        console.error("Error getting page configuration:", error);
        resolve(true);
      }
    });
  }
}


import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root',
})
export class AnalyticService {
    constructor(private http: HttpClient) { }

    constructGoogleAnalytic(el, ea, name, ipd?, rname?) {
        const date = new Date().toString();
        const gtagClient = sessionStorage.getItem('gtag');
        const measurement_id = `G-834D8WQXDD`;
        const api_secret = environment.gtag;
        const prodtype = 'd21';
        let licId = '', user_id = -1, first_name = '', last_name = '', company_name = '',
            userName = '', company_id = -1, email = '', title = '', product_name = '';
        let UserInfoStorage = sessionStorage.getItem('UserCertInfo');
        if (UserInfoStorage === null) { return; }
        let locStorage = JSON.parse(UserInfoStorage)
        if (locStorage.license_id !== undefined) {
            licId = locStorage.license_id;
        };
        if (locStorage.product_name !== undefined) {
            product_name = locStorage.product_name;
        };
        if (locStorage.qUser !== undefined && locStorage.qUser.length) {
            if (locStorage.qUser[0].user_id !== undefined) {
                user_id = locStorage.qUser[0].user_id;
            };
            if (locStorage.qUser[0].first_name !== undefined) {
                first_name = locStorage.qUser[0].first_name;
            };
            if (locStorage.qUser[0].company_id !== undefined) {
                company_id = locStorage.qUser[0].company_id;
            };
            if (locStorage.qUser[0].company_name !== undefined) {
                company_name = locStorage.qUser[0].company_name;
            };
            if (locStorage.qUser[0].email !== undefined) {
                email = locStorage.qUser[0].email;
            };
            if (locStorage.qUser[0].last_name !== undefined) {
                last_name = locStorage.qUser[0].last_name;
            };
            if (locStorage.qUser[0].title !== undefined) {
                title = locStorage.qUser[0].title;
            };
        };
        if (last_name.length && first_name.length) {
            userName = last_name + ', ' + first_name;
        };
        if (ea === undefined) {
            ea = 'Open';
        };
        if (name === undefined) {
            name = 'undefined';
        };
        if (title === undefined) {
            title = '';
        };
        let body = {
            client_id: gtagClient,
            events: [{
              name: 'ted',
              params: {
                cid: licId,
                ec: name,
                ea: ea,
                el: el,
                cs: userName,
                cm: date,
                cn: company_name,
                uid: user_id,
                ds: prodtype,
                cd1: email,
                cd2: userName,
                cd10: title,
                cd3: company_name,
                cd4: product_name,
                cd: name,
                an: prodtype,
                cd6: ipd,
                cd11: rname?rname:''
              }
            }]
        }
        this.http.post(`https://google-analytics.com/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`, JSON.stringify(body)).pipe(catchError(this._errorHandler))
            .subscribe((resp: any) => { });
    }

    _errorHandler(error: Response) {
        console.error(error);
        return throwError(error || 'Server Error');
    }
}
